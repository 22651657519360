<template>
  <div>
    <multiselect
      class="multiselect-employees"
      v-model="selectedEmployees"
      :options="filteredEmployees"
      track-by="number"
      v-on:search-change="onFilterEmployees"
      v-on:select="onSelectEmployee"
      v-on:remove="onSelectEmployee"
      :filter="true"
      :internal-search="false"
      :close-on-select="false"
      :clear-on-select="false"
      :multiple="true"
      :show-labels="false"
      :allow-empty="true"
      :deselectLabel="$t('deselectLabel')"
      selectLabel=""
      selectedLabel=""
      :placeholder="'Mitarbeiter ' + $t('select') + '/suchen'"
    >
      <template slot="singleLabel" slot-scope="{ option }">
        {{ option.firstname }} {{ option.lastname }}
      </template>
      <template slot="option" slot-scope="{ option }">
        {{ option.firstname }} {{ option.lastname }}
      </template>
      <template slot="tag" slot-scope="{ option }">
        <div>{{ option.firstname }} {{ option.lastname }}</div>
      </template>
      <template slot="noResult">
        <div>{{ $t('no_results') }}</div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  props: {
    employees: Array,
  },
  model: {
    prop: 'employees',
    event: 'select',
  },
  data() {
    return {
      filteredEmployees: [],
      selectedEmployees: this.employees,
    };
  },

  computed: {
    ...mapGetters(['getEmployees']),
  },

  methods: {
    ...mapActions(['fetchEmployees']),

    /**
     *
     */
    async onSelectEmployee(row) {
      //   console.log('onSelectEmployee', row);
      this.$emit('select', this.selectedEmployees);
    },

    /**
     *
     */
    async onFilterEmployees(query) {
      // console.log(query);
      this.filteredEmployees = [];
      this.getEmployees.map((employee) => {
        if (
          (employee.firstname && employee.firstname.toLowerCase().indexOf(query) >= 0) ||
          (employee.lastname && employee.lastname.toLowerCase().indexOf(query) >= 0)
        ) {
          this.filteredEmployees.push(employee);
        }
      });
    },
  },

  async mounted() {
    await this.fetchEmployees({
      page: 0,
      pageSize: 1000,
      sortField: 'lastname',
      sortOrder: 1,
      filters: { activeState: { value: 'ACTIVE' } },
    });
    this.filteredEmployees = this.getEmployees;
  },

  watch: {
    employees(newEmployees, oldEmployees) {
      this.selectedEmployees = newEmployees;
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect__content-wrapper {
  position: absolute;
}

.multiselect-employees {
  min-width: 200px;
  max-width: 300px;
  position: relative;
}
</style>
