<template>
  <div>
    <multiselect
      class="multiselect-itemStorages"
      v-model="selectedItemStorages"
      :options="filteredItemStorages"
      track-by="number"
      v-on:search-change="onFilterItemStorages"
      v-on:select="onSelectItemStorage"
      v-on:remove="onSelectItemStorage"
      :filter="true"
      :internal-search="false"
      :close-on-select="false"
      :clear-on-select="false"
      :multiple="true"
      :show-labels="false"
      :allow-empty="true"
      :deselectLabel="$t('deselectLabel')"
      selectLabel=""
      selectedLabel=""
      :placeholder="'Fahrzeug ' + $t('select') + '/suchen'"
    >
      <template slot="singleLabel" slot-scope="{ option }">
        {{ option.name }}
      </template>
      <template slot="option" slot-scope="{ option }">
        {{ option.name }}
      </template>
      <template slot="tag" slot-scope="{ option }">
        <div>{{ option.name }}</div>
      </template>
      <template slot="noResult">
        <div>{{ $t('no_results') }}</div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import { FilterMatchMode } from 'primevue/api';

export default {
  components: {
    Multiselect,
  },
  props: {
    itemStorages: Array,
  },
  model: {
    prop: 'itemStorages',
    event: 'select',
  },
  data() {
    return {
      filteredItemStorages: [],
      selectedItemStorages: this.itemStorages,
    };
  },

  computed: {
    ...mapGetters(['getItemStorages']),
  },

  methods: {
    ...mapActions(['fetchItemStoragesPaginated']),

    /**
     *
     */
    async onSelectItemStorage(row) {
      //   console.log('onSelectItemStorage', row);
      this.$emit('select', this.selectedItemStorages);
    },

    /**
     *
     */
    async onFilterItemStorages(query) {
      this.filteredItemStorages = [];
      this.getItemStorages.map((itemStorage) => {
        if (itemStorage.name && itemStorage.name.toLowerCase().indexOf(query) >= 0) {
          // console.log(itemStorage);
          this.filteredItemStorages.push(itemStorage);
        }
      });
    },
  },

  async mounted() {
    await this.fetchItemStoragesPaginated({
      pagination: { skip: 0, pageSize: 100 },
      sorting: { sortField: 'number', sortOrder: 1 },
      filters: { itemStorageType: { value: 'VEHICLE', matchMode: FilterMatchMode.CONTAINS } },
    });
    this.filteredItemStorages = this.getItemStorages;
  },

  watch: {
    itemStorages(newItemStorages, oldItemStorages) {
      this.selectedItemStorages = newItemStorages;
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect__content-wrapper {
  position: absolute;
}

.multiselect-itemStorages {
  min-width: 200px;
  max-width: 300px;
  position: relative;
}
</style>
