<template>
  <div class="appointmentCapacities">
    <DataTable
      class="p-datatable-sm"
      :value="appointmentCapacities"
      :loading="isLoading"
      editMode="cell"
      :rowClass="rowClass"
      :rowHover="true"
      dataKey="id"
      :lazy="true"
      :filters.sync="tableState.filters"
      stateStorage="local"
      :stateKey="filterName"
      @cell-edit-complete="onCellEditComplete"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
      :totalRecords="getAppointmentCapacityCount"
      :paginator="true"
      :rows.sync="tableState.pagination.rowsPerPage"
      :first="pageOffset"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="
        $t('show') +
          ' {first} ' +
          $t('to') +
          ' {last} ' +
          $t('of') +
          ' {totalRecords} ' +
          $t('records')
      "
      :rowsPerPageOptions="[20, 50, 100]"
      :sortField="tableState.sortField"
      :sortOrder="tableState.sortOrder"
    >
      <!-- <template #header>
        <div class="table-header">
          <div class="table-header-left"></div>
          <div class="table-header-right">
            <button class="btn btn-narrow btn-inverse ml-2 mb-2" @click.prevent="onRemoveFilters()">
              <i class="fa fa-remove mr-2"></i>
              {{ $t('remove_filters') }}
            </button>

            <div class="button-right">
              <button class="btn btn-success mb-2 right" @click="onCreateAppointmentCapacity">
                <i class="fa fa-plus-circle mr-2"></i>
                {{ $t('new') }}
              </button>
            </div>
          </div>
        </div>
      </template> -->
      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <!-- <Column field="id" :header="$t('id')" sortable> </Column> -->

      <Column field="date" :header="$t('date')" sortable :headerStyle="{ width: '100px' }">
        <template #body="slotProps">
          <span v-if="slotProps.data.date">
            {{ $date(slotProps.data.date) }}
          </span>
        </template>

        <template #editor="slotProps">
          <Calendar v-model="slotProps.data[slotProps.column.field]" :manualInput="true">
          </Calendar>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          >
          </Calendar>
        </template>
      </Column>

      <!-- <Column field="capacity" :header="$t('capacity')" sortable>
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.field]" />
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column> -->

      <Column field="zipArea" :header="$t('zipArea')" sortable>
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.field]" />
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="appointment.number" :header="$t('appointment')" sortable> </Column>

      <Column
        field="appointmentType"
        :header="$t('appointmentType')"
        :showFilterMenu="false"
        :styles="{ width: '7%' }"
        sortable
      >
        <template #body="slotProps">
          <b-badge :variant="getAppointmentTypeColor(slotProps.data.appointmentType)">
            {{ $t(slotProps.data.appointmentType) }}
          </b-badge>
        </template>

        <template #editor="{ data, field }">
          <Dropdown
            v-model="data[field]"
            :options="getEnumValues('AppointmentType')"
            :placeholder="$t('select')"
          >
            <template #option="slotProps">
              <b-badge :variant="getAppointmentTypeColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
            <template #value="slotProps">
              <b-badge :variant="getAppointmentTypeColor(slotProps.value)">
                {{ $t(slotProps.value) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>

        <template #filter>
          <MultiSelect
            :value="tableState.customFilters.appointmentTypes"
            :options="appointmentTypes"
            @input="onappointmentTypeFilter"
            optionLabel="label"
            :placeholder="$t('appointmentType')"
            display="chip"
            :style="{ 'max-width': '100px' }"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Multiselect from 'vue-multiselect';
import MultiSelect from 'primevue/multiselect';
import { FilterMatchMode } from 'primevue/api';
import { getAppointmentTypeColor } from '@/helpers/colors';

export default {
  name: 'AppointmentCapacityTable',
  components: {
    DataTable,
    Column,
    InputText,
    Calendar,
    Dropdown,
    // Multiselect,
    MultiSelect,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      /** persistent table filters  */
      defaultFilters: {
        capacity: { value: null, matchMode: FilterMatchMode.EQUALS },
        zipArea: { value: null, matchMode: FilterMatchMode.CONTAINS },
        date: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
      },
      defaultCustomFilters: {
        appointmentTypes: [],
      },

      tableState: {
        pagination: {
          page: 0,
          rowsPerPage: 50,
        },
        filters: this.defaultFilters,
        customFilters: this.defaultCustomFilters,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters([
      'isLoading',
      'getAppointmentCapacities',
      'getEnumValues',
      'getProjects',
      'getUsers',
      'getAppointmentCapacityCount',
    ]),

    appointmentCapacities() {
      return this.getAppointmentCapacities;
    },
    appointmentTypes() {
      const appointmentTypes = this.getEnumValues('AppointmentType').map((appointmentType) => {
        return { value: appointmentType, label: this.$t(appointmentType) };
      });
      return appointmentTypes;
    },
    pageOffset() {
      return this.tableState.pagination.page * this.tableState.pagination.rowsPerPage;
    },

    filterName() {
      return 'appointment-capacity-table-filters';
    },

    customFilterName() {
      return 'appointment-capacity-custom=table-filters';
    },
  },

  methods: {
    ...mapActions([
      'fetchEnumValues',
      'fetchAppointmentCapacities',
      'createAppointmentCapacity',
      'updateAppointmentCapacity',
      'initAppointmentCapacity',
      'fetchProjects',
      'fetchProjectsPaginated',
      'fetchProjectsPaginatedByClient',
      'deleteAppointmentCapacity',
      'sendAppointmentCapacityMail',
    ]),
    getAppointmentTypeColor,

    /**
     * Load remote table data
     */
    async loadAppointmentCapacityData() {
      await this.fetchAppointmentCapacities({
        page: this.pageOffset,
        pageSize: this.tableState.pagination.rowsPerPage,
        sortField: this.tableState.sortField,
        sortOrder: this.tableState.sortOrder,
        filters: { ...this.tableState.filters, ...this.tableState.customFilters },
      });
    },

    onCreateAppointmentCapacity() {
      this.initAppointmentCapacity();
    },

    /**
     * send appointmentCapacity data to server after editing a cell
     * @param {*} event
     */
    async onCellEditComplete(event) {
      let { data, newValue, field } = event;
      // console.log('onCellEditComplete():', event);
      // console.log(data, newValue, field);
      data[field] = newValue;

      this.save(data);
    },

    async save(appointmentCapacity) {
      console.log('save()', appointmentCapacity);
      if (appointmentCapacity.id === '_new') {
        await this.createAppointmentCapacity(appointmentCapacity);
      } else {
        await this.updateAppointmentCapacity(appointmentCapacity);
      }
    },

    /**
     * Reset filter settings
     */
    async onRemoveFilters(event) {
      this.tableState.filters = this.defaultFilters;
      this.tableState.customFilters = this.defaultCustomFilters;

      localStorage.removeItem(this.filterName);
      localStorage.removeItem(this.customFilterName);
      await this.loadAppointmentCapacityData();
    },

    /**
     * Load more results from server or cache on pagination click
     */
    async onPage(event) {
      this.tableState.pagination.page = event.page;
      this.tableState.pagination.rowsPerPage = event.rows;
      // console.log('onPage', event);
      this.loadAppointmentCapacityData();
    },
    /**
     * Load results from server/cache on sort
     */
    async onSort(event) {
      this.tableState.sortField = event.sortField;
      this.tableState.sortOrder = event.sortOrder;
      this.loadAppointmentCapacityData();
    },
    /**
     * Load results from server/cache on filter
     */
    async onFilter(event) {
      this.loadAppointmentCapacityData();
    },

    /**
     * restore filters from local storage
     * @param {*} event
     */
    async onStateRestore(event) {
      // console.log('onStateRestore', event);

      this.setupFilters();
      if (this.tableState) {
        this.tableState.sortField = event.sortField ? event.sortField : this.defaultSortField;
        this.tableState.sortOrder = event.sortOrder ? event.sortOrder : this.defaultSortOrder;
        this.tableState.pagination.page = 0;
        this.tableState.pagination.rowsPerPage = event.rows;
        this.tableState.pagination.rowsPerPage = event.rows;
      }
      const customFiltersFromStorage = JSON.parse(localStorage.getItem(this.customFilterName));
      this.tableState.customFilters = customFiltersFromStorage
        ? customFiltersFromStorage
        : this.defaultCustomFilters;
      // console.log('onStateRestore', this.tableState.customFilters);
    },

    setupFilters() {
      // console.log('setupFilters', this.tableState);

      this.tableState.filters = this.tableState.filters
        ? this.tableState.filters
        : this.defaultFilters;
      this.tableState.pagination.page = 0;
      this.tableState.pagination.rowsPerPage = 50;
      this.tableState.customFilters = this.tableState.customFilters
        ? this.tableState.customFilters
        : this.defaultCustomFilters;
      this.tableState.sortField = this.tableState.sortField
        ? this.tableState.sortField
        : this.defaultSortField;

      this.tableState.sortOrder = this.tableState.sortOrder
        ? this.tableState.sortOrder
        : this.defaultSortOrder;
    },
    /**
     * make new table entries identifiable by background color
     */
    rowClass(data) {
      return data.id === '_new' ? 'new' : null;
    },

    /**
     * When a project is selected from multiselect save it to the appointmentCapacity in the corresponding row
     */
    async onSelectProject(row) {
      const { data } = row;
      this.save(data);
    },

    /**
     * When an user is selected from multiselect save it to the appointmentCapacity in the corresponding row
     */
    async onSelectUser(row) {
      const { data } = row;
      // console.log(data);
      this.save(data);
    },

    async onappointmentTypeFilter(value) {
      this.tableState.customFilters.appointmentTypes = this.appointmentTypes.filter(
        (appointmentType) => value.includes(appointmentType)
      );
      localStorage.setItem(this.customFilterName, JSON.stringify(this.tableState.customFilters));
      await this.loadAppointmentCapacityData();
    },
  },
  async created() {
    this.setupFilters();

    // console.log('created()', this.tableState);
  },
  async mounted() {
    await this.loadAppointmentCapacityData();
  },
};
</script>

<style scoped lang="scss">
::v-deep .center .p-column-header-content {
  justify-content: center;
}

::v-deep .p-datatable .p-datatable-tbody td {
  text-align: left;
}
::v-deep .p-datatable .p-datatable-tbody td.center {
  text-align: center;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new {
  background: $light-green;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new:hover {
  background: $green;
}

.multiselect-project {
  max-width: 500px;
  min-width: 350px;
  display: inline-block;
}
.multiselect-users {
  min-width: 230px;
  display: inline-block;
}

::v-deep .multiselect__tags {
  border: 0;
  border-radius: 0;
}

::v-deep .multiselect-users .multiselect__input {
  padding-top: 1em;
}

/** Fix Dropdown overlay */
.p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 37rem;
}

::v-deep .p-datepicker-title {
  display: flex;
}

::v-deep .btn-icon > button {
  padding: 0;
}

.btn-xs {
  border-radius: 0;
}

::v-deep .p-column-filter-clear-button {
  display: none;
}
::v-deep .p-column-filter-menu-button {
  display: none;
}
</style>
