<template>
  <div class="mb-4 p-2">
    <h5>{{ $t('appointment') }} {{ $t('edit') }}</h5>
    <div class="grid">
      <div>{{ $t('date') }}</div>
      <div>{{ $date(appointmentCapacity.appointment.installationStartAt) }}</div>
      <div>{{ $t('start') }}</div>
      <div>
        <Calendar
          v-model="appointmentCapacity.appointment.installationStartAt"
          :showTime="true"
          :timeOnly="true"
          :manualInput="true"
          :stepMinute="15"
        >
        </Calendar>
      </div>
      <div>{{ $t('end') }}</div>
      <div>
        <Calendar
          v-model="appointmentCapacity.appointment.installationEndAt"
          :showTime="true"
          :timeOnly="true"
          :manualInput="true"
          :stepMinute="15"
        >
        </Calendar>
      </div>

      <div>{{ $t('appointmentType') }}</div>
      <div>
        <b-badge
          :variant="getAppointmentTypeColor(appointmentCapacity.appointment.appointmentType)"
        >
          {{ $t(appointmentCapacity.appointment.appointmentType) }}
        </b-badge>
      </div>

      <div>{{ $t('appointmentState') }}</div>
      <div>
        <Dropdown
          v-model="appointmentCapacity.appointment.appointmentState"
          :options="getEnumValues('AppointmentState')"
          :placeholder="$t('select')"
        >
          <template #option="slotProps">
            <b-badge :variant="getAppointmentStateColor(slotProps.option)">
              {{ $t(slotProps.option) }}
            </b-badge>
          </template>
          <template #value="slotProps">
            <b-badge :variant="getAppointmentStateColor(slotProps.value)">
              {{ $t(slotProps.value) }}
            </b-badge>
          </template>
        </Dropdown>
      </div>

      <div>{{ $t('confirmed') }}</div>
      <div>
        <Dropdown
          v-model="appointmentCapacity.appointment.appointmentApprovedState"
          :options="yesNo()"
          :placeholder="$t('select')"
        >
          <template #option="slotProps">
            <b-badge :variant="getYesNoStateColor(slotProps.option)">
              {{ $t(slotProps.option) }}
            </b-badge>
          </template>
          <template #value="slotProps">
            <b-badge :variant="getYesNoStateColor(slotProps.value)">
              {{ $t(slotProps.value) }}
            </b-badge>
          </template>
        </Dropdown>
      </div>

      <div>{{ $t('employees') }}</div>
      <div>
        <MultiselectEmployees
          v-model="appointmentCapacity.appointment.employees"
        ></MultiselectEmployees>
      </div>

      <div>{{ $t('vehicles') }}</div>
      <div>
        <MultiselectItemStorages
          v-model="appointmentCapacity.appointment.itemStorages"
        ></MultiselectItemStorages>
      </div>

      <div>{{ $t('notes') }}</div>
      <div>
        <InputText v-model="appointmentCapacity.appointment.description" />
      </div>
    </div>

    <hr />
    <h5>{{ $t('customer') }} {{ $t('select') }}</h5>
    <div class="grid">
      <div>{{ $t('zipArea') }}</div>
      <div>
        <MultiSelect
          :value="projectsFilter.filters.customerZipAreas"
          :options="zipAreas"
          @input="onCustomerZipAreaFilter"
          optionLabel="label"
          :placeholder="$t('zipArea')"
          display="chip"
          style="max-width: 260px"
        />
      </div>
      <div>{{ $t('projectState') }}</div>
      <div>
        <MultiSelect
          :value="projectsFilter.filters.projectStates"
          :options="projectStates"
          @input="onProjectStateFilter"
          optionLabel="label"
          :placeholder="$t('projectState')"
          display="chip"
          style="max-width: 260px"
        />
      </div>
      <div>{{ $t('customer') }}</div>
      <div>
        <multiselect
          class="multiselect-project"
          :placeholder="$t('projectCustomerSearch')"
          v-model="appointmentCapacity.appointment.project"
          :options="filteredProjects"
          :allow-empty="true"
          track-by="number"
          :deselectLabel="$t('deselectLabel')"
          selectLabel=""
          selectedLabel=""
          :internal-search="false"
          :show-labels="true"
          @search-change="onProjectSearch"
          @select="onSelectProject"
        >
          <template slot="singleLabel" slot-scope="{ option }">
            <div>{{ option.customer.lastname }}, {{ option.customer.firstname }},</div>
            <div>
              {{ option.customer.street }} {{ option.customer.streetNumber }},
              {{ option.customer.zip }}
              {{ option.customer.city }}
            </div>
          </template>
          <template slot="option" slot-scope="{ option }">
            <div>{{ option.customer.lastname }}, {{ option.customer.firstname }},</div>
            <div>
              {{ option.customer.street }} {{ option.customer.streetNumber }},
              {{ option.customer.zip }}
              {{ option.customer.city }}
            </div>
          </template>
        </multiselect>
      </div>

      <template v-if="appointmentCapacity.appointment.project">
        <div>{{ $t('project') }} #</div>
        <div>
          <router-link
            target="_blank"
            :to="{
              name: 'ProjectEditPage',
              params: { projectNumber: appointmentCapacity.appointment.project.number },
            }"
            >{{ appointmentCapacity.appointment.project.number.toString().padStart(4, '0') }}
            <i class="fa fa-sm fa-external-link"></i>
          </router-link>
        </div>

        <div>{{ $t('projectState') }}</div>
        <div>
          <Dropdown
            v-model="selectedProjectState"
            :options="getEnumValues('ProjectState')"
            :placeholder="$t('select')"
          >
            <template #option="slotProps">
              <b-badge :variant="getProjectStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
            <template #value="slotProps">
              <b-badge :variant="getProjectStateColor(slotProps.value)">
                {{ $t(slotProps.value) }}
              </b-badge>
            </template>
          </Dropdown>
        </div>

        <div>{{ $t('phone') }}</div>
        <div>
          {{ appointmentCapacity.appointment.project.customer.phone }}
        </div>

        <div>{{ $t('mobile') }}</div>
        <div>
          {{ appointmentCapacity.appointment.project.customer.mobile }}
        </div>

        <div>{{ $t('employer') }}</div>
        <div>
          <span v-if="appointmentCapacity.appointment.project.employer">
            {{ appointmentCapacity.appointment.project.employer.name }}</span
          >
          <span v-else>N/A</span>
        </div>

        <div>{{ $t('dcTimeExpenditure') }}</div>
        <div>
          <span v-if="appointmentCapacity.appointment.project.technicalReview">
            {{ appointmentCapacity.appointment.project.technicalReview.dcTimeExpenditure }}</span
          >
          <span v-else>N/A</span>
        </div>

        <div>{{ $t('acTimeExpenditure') }}</div>
        <div>
          <span v-if="appointmentCapacity.appointment.project.technicalReview">
            {{ appointmentCapacity.appointment.project.technicalReview.acTimeExpenditure }}</span
          >
          <span v-else>N/A</span>
        </div>

        <div>{{ $t('dcNotes') }}</div>
        <div>
          <span
            v-if="appointmentCapacity.appointment.project.technicalReview"
            v-html="appointmentCapacity.appointment.project.technicalReview.dcNotes"
          >
          </span>
          <span v-else>N/A</span>
        </div>

        <div>{{ $t('acNotes') }}</div>
        <div>
          <span
            v-if="appointmentCapacity.appointment.project.technicalReview"
            v-html="appointmentCapacity.appointment.project.technicalReview.acNotes"
          >
          </span>
          <span v-else>N/A</span>
        </div>
      </template>
    </div>
    <hr />

    <Button
      @click="onSave"
      :label="$t('save')"
      class="p-button-success mr-2 mt-2 "
      icon="pi pi-check"
    />

    <ConfirmButton
      colorVariant="success"
      :disabled="emailButtonDisabled()"
      :callbackId="this.appointmentCapacity.appointment.id"
      :confirmCallback="onSendAppointmentConfirmationMail"
      class="mr-2 mt-2 p-button-secondary"
    >
      <template v-slot:buttonText>
        <span class="pi pi-envelope p-button-icon p-button-icon-left"></span>
        <span class="button-label ml-2">Termin bestätigen</span>
      </template>
      <template v-slot:header> {{ $t('confirm_appointment') }}? </template>
      <template v-slot:confirmButtonText>
        {{ $t('confirm_appointment') }}
      </template>
      <template v-slot:content>
        E-Mail-Bestätigung wird versendet an:
        <strong
          v-if="
            appointmentCapacity.appointment.project &&
              appointmentCapacity.appointment.project.customer.email
          "
        >
          {{ appointmentCapacity.appointment.project.customer.email }}</strong
        >
      </template>
    </ConfirmButton>

    <Button
      @click="onCancel"
      :label="$t('cancel')"
      class="p-button-secondary mr-2 mt-2"
      icon="pi pi-times"
    />
    <ConfirmDeleteButton class="mt-2" v-if="canDelete" :confirmCallback="onDelete">
      <template #buttonText> {{ $t('slot') }} {{ $t('delete') }} </template>
    </ConfirmDeleteButton>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  getAppointmentTypeColor,
  getAppointmentStateColor,
  getYesNoStateColor,
  getProjectStateColor,
} from '@/helpers/colors';
import { yesNo } from '@/helpers/enums';
import Multiselect from 'vue-multiselect';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import { FilterMatchMode } from 'primevue/api';
import Button from 'primevue/button';
import ConfirmDeleteButton from '@/components/ConfirmDeleteButton';
import Dropdown from 'primevue/dropdown';
import ConfirmButton from '@/components/ConfirmButton';
import MultiselectEmployees from '@/components/MultiselectEmployees';
import MultiselectItemStorages from '../MultiselectItemStorages.vue';
import MultiSelect from 'primevue/multiselect';

export default {
  components: {
    InputText,
    Calendar,
    Multiselect,
    Button,
    ConfirmDeleteButton,
    Dropdown,
    ConfirmButton,
    MultiselectEmployees,
    MultiselectItemStorages,
    MultiSelect,
  },
  props: {
    appointmentCapacity: {
      type: Object,
      default: null,
    },
    closeCallback: Function,
    deleteCallback: Function,
  },
  data() {
    return {
      selectedProjectState: null,
      /** filters for project multiselect */
      filteredProjects: [],
      projectsFilter: {
        pagination: {
          page: 0,
          rowsPerPage: 50,
        },
        sortField: 'number',
        sortOrder: -1,
        filterName: 'appointments-project-filters',
        filters: {
          number: { value: null, matchMode: FilterMatchMode.EQUALS },
          customerZip: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          projectCustomerSearch: { value: null, matchMode: FilterMatchMode.CONTAINS },
          projectStates: [],
          customerZipAreas: [],
        },
      },
    };
  },

  computed: {
    ...mapGetters(['getProjects', 'getAppointmentByNumber', 'getEnumValues', 'getProjectByNumber']),

    /**
     * only slots without appointment can be deleted
     */
    canDelete() {
      return this.appointmentCapacity.appointment.id === '_new';
    },

    projectStates() {
      const projectStates = this.getEnumValues('ProjectState').map((projectState) => {
        return { value: projectState, label: this.$t(projectState) };
      });
      return projectStates;
    },

    zipAreas() {
      return [
        { label: '0', value: '0' },
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
      ];
    },
  },

  methods: {
    ...mapActions([
      'fetchProjectsPaginated',
      'fetchProjectByNumber',
      'createAppointment',
      'updateAppointment',
      'updateAppointmentCapacity',
      'sendAppointmentMail',
      'updateProject',
      'fetchEnumValues',
    ]),
    getAppointmentTypeColor,
    getAppointmentStateColor,
    getYesNoStateColor,
    yesNo,
    getProjectStateColor,

    /**
     *
     */
    async onProjectStateFilter(value) {
      this.projectsFilter.filters.projectStates = this.projectStates.filter((projectState) =>
        value.includes(projectState)
      );
      await this.loadProjectData();
    },

    async onCustomerZipAreaFilter(zipAreaFilter) {
      this.projectsFilter.filters.customerZipAreas = this.zipAreas.filter((zipArea) =>
        zipAreaFilter.includes(zipArea)
      );
      await this.loadProjectData();
    },

    /**
     * only enable email button if customer has the email field filled
     */
    emailButtonDisabled() {
      return !(
        this.appointmentCapacity.appointment.project &&
        this.appointmentCapacity.appointment.project.customer.email &&
        this.appointmentCapacity.appointment.appointmentApprovedState === 'YES'
      )
        ? true
        : false;
    },

    /**
     * send confirmation mail
     */
    async onSendAppointmentConfirmationMail(appointment) {
      await this.save();
      await this.sendAppointmentMail({
        appointmentNumber: this.appointmentCapacity.appointment.number,
      });
    },

    /**
     * @todo DRY
     */
    async onProjectSearch(rawQuery) {
      await this.loadProjectData();

      const query = rawQuery.toLowerCase();
      this.projectsFilter.filters.projectCustomerSearch.value = query;
      await this.loadProjectData();
    },

    /**
     * cache project changes which are sent to the server
     */
    async onSelectProject(project) {
      this.selectedProjectState = project.projectState;
    },

    /**
     * load remote project data
     */
    async loadProjectData() {
      // this.projectsFilter.filters.customerZip.value = this.appointmentCapacity.zipArea.toString();
      // this.projectsFilter.filters.projectStates = [{ value: 'PROJECT_STATE_NEW' }];

      await this.fetchProjectsPaginated({
        page: this.projectsFilter.pagination.page,
        pageSize: this.projectsFilter.pagination.rowsPerPage,
        sortField: this.projectsFilter.sortField,
        sortOrder: this.projectsFilter.sortOrder,
        filters: { ...this.projectsFilter.filters },
      });
      this.filteredProjects = this.getProjects;

      if (this.appointmentCapacity.appointment.project) {
        this.selectedProjectState = this.appointmentCapacity.appointment.project.projectState;
      }
    },

    /**
     * - save appointment
     * - save appointment id on appointmentCapacity
     * @param {*} appointment
     */
    async save() {
      if (this.appointmentCapacity.appointment.project) {
        // refetch project first to avoid inconsistencies
        await this.fetchProjectByNumber(this.appointmentCapacity.appointment.project.number);
        this.appointmentCapacity.appointment.project = this.getProjectByNumber(
          this.appointmentCapacity.appointment.project.number
        );

        // only assign cached selectedProjectState
        this.appointmentCapacity.appointment.project.projectState = this.selectedProjectState;

        // console.log(this.appointmentCapacity.appointment);
        await this.updateProject(this.appointmentCapacity.appointment.project);
      }

      if (this.appointmentCapacity.appointment.number === '_new') {
        const appointmentNumber = await this.createAppointment(
          this.appointmentCapacity.appointment
        );

        this.appointmentCapacity.appointment = this.getAppointmentByNumber(appointmentNumber);
        this.updateAppointmentCapacity(this.appointmentCapacity);
      } else {
        await this.updateAppointment(this.appointmentCapacity.appointment);
      }
    },

    /**
     * - save appointment
     * - save appointment id on appointmentCapacity
     * @param {*} appointment
     */
    async onSave() {
      await this.save();

      this.$eventHub.$emit('appointment-capacity-edit:close', { close: true });
      if (this.closeCallback) {
        this.closeCallback();
      }
    },

    /**
     * Close edit dialog
     * when cancelling a new event: reset data
     */
    async onCancel() {
      if (this.appointmentCapacity.appointment.number === '_new') {
        this.appointmentCapacity.appointment = null;
        this.appointmentCapacity.start = null;
        this.appointmentCapacity.end = null;
      }

      this.$eventHub.$emit('appointment-capacity-edit:close', { close: true });
      if (this.closeCallback) {
        this.closeCallback();
      }
    },

    /**
     * delete an event slot
     * @todo delete corresponding event
     */
    async onDelete() {
      if (this.deleteCallback) {
        this.deleteCallback(this.appointmentCapacity._id);
      }
      if (this.closeCallback) {
        this.closeCallback();
      }
      this.$eventHub.$emit('appointment-capacity-edit:close', { close: true });
    },
  },

  async mounted() {
    await this.fetchEnumValues('ProjectState');
    await this.fetchEnumValues('AppointmentState');
    await this.loadProjectData();
  },

  watch: {
    appointmentCapacity(newAppointmentCapacity, oldValue) {
      if (
        newAppointmentCapacity.appointment.project &&
        newAppointmentCapacity.appointment.project.projectState
      ) {
        this.selectedProjectState = newAppointmentCapacity.appointment.project.projectState;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: 35% 65%;
  gap: 0.5em;
}
</style>
