<template>
  <div class="tasks">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <Panel>
      <template #header>
        <h6>Slots hinzufügen</h6>
      </template>
      <div class="grid">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-calendar"></i>
          </span>
          <Calendar
            ref="appointmentCapacityCalendar"
            v-model="dateRange"
            selectionMode="range"
            class="p-column-filter"
            dateFormat="dd.mm.yy"
            :placeholder="$t('date')"
            :showWeek="true"
            :yearNavigator="true"
            :monthNavigator="true"
            v-on:date-select="onDateRangeFilter"
          >
          </Calendar>
        </div>

        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            Slots
          </span>
          <InputText :placeholder="$t('capacity')" v-model="appointmentCapacityTemplate.capacity" />
        </div>

        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            {{ $t('appointmentType') }}
          </span>
          <Dropdown
            v-model="appointmentCapacityTemplate.appointmentType"
            :options="getEnumValues('AppointmentType')"
            :placeholder="$t('select')"
          >
            <template #option="slotProps">
              <b-badge :variant="getAppointmentTypeColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
            <template #value="slotProps">
              <b-badge :variant="getAppointmentTypeColor(slotProps.value)">
                {{ $t(slotProps.value) }}
              </b-badge>
            </template>
          </Dropdown>
        </div>

        <!-- <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            PLZ-Bereich
          </span>
          <Dropdown
            v-model="appointmentCapacityTemplate.zipArea"
            :options="zipAreas"
            :placeholder="$t('select')"
          >
            <template #option="slotProps">
              <b-badge>
                {{ slotProps.option }}
              </b-badge>
            </template>
            <template #value="slotProps">
              <b-badge>
                {{ slotProps.value }}
              </b-badge>
            </template>
          </Dropdown>
        </div> -->

        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            {{ $t('employees') }}
          </span>
          <MultiselectEmployees v-model="appointmentCapacityTemplate.employees" />
        </div>

        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            {{ $t('vehicles') }}
          </span>
          <MultiselectItemStorages v-model="appointmentCapacityTemplate.itemStorages" />
        </div>

        <div class="p-inputgroup">
          <Button
            @click="onCreateAppointmentCapacity"
            :label="$t('add')"
            class="p-button-success"
            icon="pi pi-plus"
          />
        </div>
      </div>
    </Panel>

    <Panel>
      <div class="grid-calendar">
        <div>
          <div id="calendar-filters">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                {{ $t('appointmentType') }}
              </span>
              <MultiSelect
                :value="tableState.filters.appointmentTypes"
                :options="appointmentTypes"
                @input="onAppointmentTypeFilter"
                optionLabel="label"
                :placeholder="$t('appointmentType')"
                display="chip"
                :style="{ 'max-width': '300px' }"
              />
            </div>
          </div>
          <AppointmentCapacitiesCalendar
            :appointmentCapacities="getAppointmentCapacities"
            :selectEventCallback="onSelectAppointmentCapacity"
            :dateChangedCallback="onSelectDateRange"
          />
        </div>
        <div class="edit" v-if="selectedAppointmentCapacity">
          <AppointmentCapacityEdit
            :appointmentCapacity="selectedAppointmentCapacity"
            :closeCallback="onCloseAppointmentCapacity"
            :deleteCallback="onDeleteAppointmentCapacity"
          />
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';
import AppointmentCapacityTable from '@/components/AppointmentCapacityTable';
import InputText from 'primevue/inputtext';
import Panel from 'primevue/panel';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import { getAppointmentTypeColor } from '@/helpers/colors';
import Button from 'primevue/button';
import AppointmentCapacitiesCalendar from '@/components/Appointments/AppointmentCapacitiesCalendar';
import AppointmentCapacityEdit from '@/components/Appointments/AppointmentCapacityEdit';
import { FilterMatchMode } from 'primevue/api';
import MultiselectEmployees from '@/components/MultiselectEmployees';
import MultiselectItemStorages from '../../components/MultiselectItemStorages.vue';
import MultiSelect from 'primevue/multiselect';

export default {
  name: 'AppointmentCapacities',
  components: {
    Breadcrumbs,
    // AppointmentCapacityTable,
    InputText,
    Panel,
    Calendar,
    Dropdown,
    Button,
    AppointmentCapacitiesCalendar,
    AppointmentCapacityEdit,
    MultiselectEmployees,
    MultiselectItemStorages,
    MultiSelect,
  },
  data() {
    return {
      selectedAppointmentCapacity: null,
      zipAreas: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      dateRange: [
        this.$dayjs()
          .startOf('week')
          .toDate(),
        this.$dayjs()
          .startOf('week')
          .add(5, 'days')
          .toDate(),
      ],
      appointmentCapacityTemplate: {
        _id: '_new',
        calendarWeek: [],
        appointmentType: 'AC',
        capacity: 1,
        // zipArea: 1,
        employees: [],
        itemStorages: [],
      },

      tableState: {
        pagination: {
          page: 0,
          rowsPerPage: 10000,
        },
        filters: {
          appointmentTypes: [],
          zipArea: { value: null, matchMode: FilterMatchMode.CONTAINS },
          dateFrom: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
          dateTo: { value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
        },
        sortField: 'createdAt',
        sortOrder: -1,
        customFilterName: 'appointment-capacities-custom-table-filters',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getEnumValues',
      'getAppointmentCapacities',
      'getAppointmentCapacity',
      'getAppointment',
    ]),
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('appointmentCapacities') },
      ];
    },
    appointmentTypes() {
      const appointmentTypes = this.getEnumValues('AppointmentType').map((appointmentType) => {
        return { value: appointmentType, label: this.$t(appointmentType) };
      });
      return appointmentTypes;
    },
    pageOffset() {
      return this.tableState.pagination.page * this.tableState.pagination.rowsPerPage;
    },
    appointmentCapacities() {
      return this.getAppointmentCapacities;
    },
  },

  methods: {
    ...mapActions([
      'fetchEnumValues',
      'initAppointmentCapacity',
      'addAppointmentCapacity',
      'fetchAppointmentCapacities',
      'fetchAppointmentCapacityPages',
      'initAppointment',
      'createAppointmentCapacity',
      'updateAppointmentCapacity',
      'deleteAppointmentCapacity',
    ]),
    getAppointmentTypeColor,

    /**
     *
     */
    async onAppointmentTypeFilter(value) {
      this.tableState.filters.appointmentTypes = this.appointmentTypes.filter((appointmentType) =>
        value.includes(appointmentType)
      );
      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.filters)
      );

      await this.loadAppointmentCapacityData();
    },

    /**
     * for each day in date range, create a slot as specified in the capacity property
     */
    async onCreateAppointmentCapacity() {
      if (this.dateRange.length <= 0) return;

      let dateIterator = this.dateRange[0];
      const lastDate = this.dateRange[1] ? this.dateRange[1] : this.dateRange[0];
      while (dateIterator <= lastDate) {
        for (let i = 0; i < this.appointmentCapacityTemplate.capacity; i++) {
          await this.saveAppointmentCapacity({
            date: dateIterator,
            ...this.appointmentCapacityTemplate,
          });
        }
        dateIterator = this.$dayjs(dateIterator)
          .add(1, 'days')
          .toDate();
      }
    },

    /**
     * Save appointmentCapacity
     */
    async saveAppointmentCapacity(appointmentCapacity) {
      // console.log('saveAppointmentCapacity()', appointmentCapacity);
      if (appointmentCapacity._id === '_new') {
        await this.createAppointmentCapacity(appointmentCapacity);
      } else {
        await this.updateAppointmentCapacity(appointmentCapacity);
      }
    },

    /**
     * Hide appointment edit form
     */
    onCloseAppointmentCapacity() {
      // console.log('onCloseAppointmentCapacity()', this.selectedAppointmentCapacity);
      if (
        this.selectedAppointmentCapacity &&
        this.selectedAppointmentCapacity.appointment &&
        this.selectedAppointmentCapacity.appointment.number === '_new'
      ) {
        this.selectedAppointmentCapacity.appointment = null;
        this.selectedAppointmentCapacity.start = null;
        this.selectedAppointmentCapacity.end = null;
      }
      this.selectedAppointmentCapacity = null;
    },

    /**
     * Delete appointment slot
     */
    async onDeleteAppointmentCapacity(appointmentCapacityId) {
      await this.deleteAppointmentCapacity(appointmentCapacityId);
    },

    /**
     * When a slot is selected, if slot empty
     * - create a new appointment and attach it to the slot
     * - carry over appointmentType from slot to appointment
     * - set date from slot
     * @param {*} event
     */
    setupSelectedAppointmentCapacity(event) {
      if (!this.selectedAppointmentCapacity.appointment) {
        this.initAppointment();
        this.selectedAppointmentCapacity.appointment = this.getAppointment('_new');
        this.selectedAppointmentCapacity.appointment.appointmentType = this.selectedAppointmentCapacity.appointmentType;
        this.selectedAppointmentCapacity.appointment.installationStartAt = this.$dayjs(
          this.selectedAppointmentCapacity.date
        )
          .startOf('day')
          .add(7, 'hour')
          .add(30, 'minute')
          .toDate();
        this.selectedAppointmentCapacity.appointment.installationEndAt = this.$dayjs(
          this.selectedAppointmentCapacity.date
        )
          .startOf('day')
          .add(17, 'hour')
          .add(30, 'minute')
          .toDate();

        this.selectedAppointmentCapacity.appointment.employees = this.selectedAppointmentCapacity.employees;
        this.selectedAppointmentCapacity.appointment.itemStorages = this.selectedAppointmentCapacity.itemStorages;
      } else {
        this.selectedAppointmentCapacity.appointment.installationStartAt = this.$dayjs(
          this.selectedAppointmentCapacity.appointment.installationStartAt
        ).toDate();
        this.selectedAppointmentCapacity.appointment.installationEndAt = this.$dayjs(
          this.selectedAppointmentCapacity.appointment.installationEndAt
        ).toDate();
      }
    },

    /**
     * When a slot is selected, reset selectedAppointmentCapacity and setup a new one
     * @param {*} event
     */
    onSelectAppointmentCapacity(event) {
      // console.log('onSelectAppointmentCapacity()', event);
      this.onCloseAppointmentCapacity();

      this.selectedAppointmentCapacity = this.getAppointmentCapacity(event);
      this.setupSelectedAppointmentCapacity();
    },

    onSelectDateRange(event) {
      // console.log('onSelectDateRange()', event);
      this.tableState.filters.dateFrom.value = event.startDate;
      this.tableState.filters.dateTo.value = event.endDate;
      this.loadAppointmentCapacityData();
    },

    /**
     * When selecting a single day, add 13 days to the date filter to represent two whole weeks.
     * Then close the calendar overlay.
     * @param {*} value
     */
    async onDateRangeFilter(value) {
      // this.dateRange[1] = this.$dayjs(value)
      //   .add(6, 'days')
      //   .toDate();
      // this.$refs.appointmentCapacityCalendar.overlayVisible = false;
    },

    /**
     * Load remote table data
     */
    async loadAppointmentCapacityData() {
      const params = {
        page: this.pageOffset,
        pageSize: this.tableState.pagination.rowsPerPage,
        sortField: this.tableState.sortField,
        sortOrder: this.tableState.sortOrder,
        filters: this.tableState.filters,
      };
      await this.fetchAppointmentCapacityPages(params);
    },
  },

  async mounted() {
    await this.fetchEnumValues('AppointmentType');
    await this.fetchEnumValues('ProjectState');
    this.dateRange[0] = this.$dayjs()
      .startOf('week')
      .toDate();
    this.dateRange[1] = this.$dayjs(this.dateRange[0])
      .add(5, 'days')
      .toDate();

    this.tableState.filters.dateFrom.value = this.dateRange[0];
    this.tableState.filters.dateTo.value = this.dateRange[1];
    await this.loadAppointmentCapacityData();
  },
};
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 16.3%));
  gap: 0.5em;
}
.grid-calendar {
  display: grid;
  grid-template-columns: 1fr auto;
  // grid-template-areas: 'calendar edit';
  gap: 1em;
}

#calendar-filters {
  margin-bottom: 1em;
}

.edit {
  overflow: auto;
  max-width: 420px;
  transition: all 3s;
}
</style>
